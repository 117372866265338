"use client";
import {
	Badge,
	ToggleButton,
	ToggleButtonGroup,
} from "@mui/material";
import { FlexBetween } from "components/flex-box";
import { useAccount } from "contexts/AccountContext";
import {
	usePathname,
	useRouter,
} from "next/navigation";
import { cdpClient } from "utils/analytics";
import {
	ACCOUNT_CLEANOUT_ROOT,
	CLEANOUT_ROOT,
} from "utils/config";

const SellAndBuySwitch = ({
	size = "medium",
}: {
	size?: "small" | "medium" | "large";
}) => {
	const actualPath = usePathname();

	const isSellActive =
		actualPath?.includes(CLEANOUT_ROOT);

	const router = useRouter();

	const { customer } =
		useAccount() || {};

	const handleChanges = (
		event: React.MouseEvent<HTMLElement>,
		newValue: string
	) => {
		if (newValue === "buy") {
			cdpClient?.track(
				"Switched to Buy Section from Toogle Button",
				{
					eventType: "click",
					category: "Header",
					label:
						"Switched to Buy Section",
				}
			);
			// navigate to buy page
			router.push("/");
		} else {
			cdpClient?.track(
				"Switched to Sell Section from Toogle Button",
				{
					eventType: "click",
					category: "Header",
					label:
						"Switched to Sell Section",
				}
			);
			// navigate to sell page
			router.push(
				customer?.has_account
					? ACCOUNT_CLEANOUT_ROOT
					: CLEANOUT_ROOT
			);
		}
	};

	return (
		<ToggleButtonGroup
			size={size}
			color='secondary'
			value={
				isSellActive ? "sell" : "buy"
			} // TODO: make it dynamic
			exclusive
			onChange={handleChanges}
			aria-label='Platform'>
			<ToggleButton
				value='buy'
				selected={!isSellActive}
				sx={{
					color: "text.primary",
				}}>
				Acheter
			</ToggleButton>

			<ToggleButton
				value='sell'
				selected={isSellActive}
				sx={{
					color: "text.primary",
				}}>
				<Badge
					color='primary'
					variant='dot'
					invisible={isSellActive}>
					<FlexBetween columnGap={0.5}>
						<div>Vendre</div>
					</FlexBetween>
				</Badge>
			</ToggleButton>
		</ToggleButtonGroup>
	);
};

export default SellAndBuySwitch;
