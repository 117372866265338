"use client";
import { Menu } from "@mui/icons-material";
import {
	Box,
	Button,
	ButtonGroup,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
} from "@mui/material";
import Scrollbar from "components/Scrollbar";
import Sidenav from "components/Sidenav";
import { useNavigation } from "contexts/NavigationContext";
import useDeviceSize from "hooks/useDeviceSize";
import useFashionPreference from "hooks/useFashionPreference";
import Link from "next/link";
import {
	usePathname,
	useRouter,
} from "next/navigation";
import CategoryVerticalMenu from "pages-sections/listing/CategoryVerticalMenu";
import {
	FC,
	Fragment,
	useEffect,
	useState,
} from "react";
import { cdpClient } from "utils/analytics";
import {
	ACCOUNT_CLEANOUT_CASHOUT_ROOT,
	ACCOUNT_CLEANOUT_ROOT,
	CLEANOUT_ROOT,
	SECTION_ROOT,
} from "utils/config";
import { sellerNavs } from "./DashboardNavbar";
import { useAccount } from "contexts/AccountContext";

const MobileMenu: FC = () => {
	const router = useRouter();
	const actualPath = usePathname();

	const isSellActive =
		actualPath?.includes(CLEANOUT_ROOT);

	const { customer } = useAccount();

	const hasConsignmentKits = Boolean(
		customer?.consignments_kits?.length
	);

	const [openDrawer, setOpenDrawer] =
		useState(false);

	const toggle = (state: boolean) => {
		if (!state) {
			cdpClient?.track(
				"Close mobile menu button clicked",
				{
					category: "Navigation",
					label: "Mobile Menu",
					eventType: "click",
				}
			);
		} else {
			cdpClient?.track(
				"Open Mobile Menu Button clicked",
				{
					category: "Navigation",
					label: "Mobile Menu",
					eventType: "click",
				}
			);
		}
		setOpenDrawer(state);
	};

	const { mainSections } =
		useNavigation();
	const { fashionPreference } =
		useFashionPreference();

	const handleSectionChange = (
		section
	) => {
		cdpClient?.track(
			"Change section in mobile menu",
			{
				category: "Navigation",
				label: "Mobile Menu",
				value: section.name,
				eventType: "click",
			}
		);

		router.push(
			`${SECTION_ROOT}/${section.handle}`
		);
	};

	useEffect(() => {
		// if path has #dialog, and dialog is not open, delete #dialog from path
		if (
			window.location.hash ===
				"#mobile-menu" &&
			!openDrawer
		) {
			window.history.replaceState(
				{
					direction: "backword",
					dialogOpen: false,
				},
				"",
				window.location.pathname +
					window.location.search
			);
		}
	}, [openDrawer]);

	useEffect(() => {
		if (!openDrawer) return;
		window.history.pushState(
			{
				direction: "forward",
				openDrawer: true,
			},
			"",
			"#mobile-menu"
		);

		const handleClose = () => {
			toggle(false);
		};

		window.addEventListener(
			"popstate",
			handleClose
		);

		return () => {
			window.removeEventListener(
				"popstate",
				handleClose
			);
		};
	}, [openDrawer]);

	const { downSm } = useDeviceSize();

	// if actual path changes, close the drawer
	useEffect(() => {
		setOpenDrawer(false);
	}, [actualPath]);

	if (isSellActive) {
		return (
			<Fragment>
				<Sidenav
					open={openDrawer}
					toggleSidenav={toggle}
					width={
						downSm ? "85vw" : "50vw"
					}
					handle={
						<IconButton
							sx={{
								flexShrink: 0,
								color: "grey.600",
							}}
							aria-label='Ouvrir le menu de vide-dressings'
							title='Ouvrir le menu de vide-dressings'>
							<Menu />
						</IconButton>
					}
					title={"Vide-dressings"}>
					<Scrollbar autoHide={false}>
						<List>
							{hasConsignmentKits && (
								<ListItem
									sx={{
										paddingLeft: 3,
									}}
									disablePadding
									disableGutters>
									<ListItemButton
										href={
											ACCOUNT_CLEANOUT_ROOT
										}
										LinkComponent={
											Link
										}>
										<ListItemText
											primary='Votre Tableau de Bord'
											primaryTypographyProps={{
												fontWeight:
													actualPath ===
													ACCOUNT_CLEANOUT_ROOT
														? "700"
														: undefined,
											}}
										/>
									</ListItemButton>
								</ListItem>
							)}
							{sellerNavs.map(
								(nav, i) => {
									const isActive =
										actualPath ===
										nav.href;
									return (
										<ListItem
											key={
												nav.title + i
											}
											sx={{
												paddingLeft: 3,
											}}
											disablePadding
											disableGutters>
											<ListItemButton
												href={nav.href}
												LinkComponent={
													Link
												}>
												<ListItemText
													primary={
														nav.title
													}
													primaryTypographyProps={{
														fontWeight:
															isActive
																? "700"
																: undefined,
													}}
												/>
											</ListItemButton>
										</ListItem>
									);
								}
							)}
							{customer?.has_account && (
								<ListItem
									sx={{
										paddingLeft: 3,
									}}
									disablePadding
									disableGutters>
									<ListItemButton
										href={
											ACCOUNT_CLEANOUT_CASHOUT_ROOT
										}
										LinkComponent={
											Link
										}>
										<ListItemText
											primary='Retirer de l argent'
											primaryTypographyProps={{
												fontWeight:
													actualPath ===
													ACCOUNT_CLEANOUT_CASHOUT_ROOT
														? "700"
														: undefined,
											}}
										/>
									</ListItemButton>
								</ListItem>
							)}
						</List>
					</Scrollbar>
				</Sidenav>
			</Fragment>
		);
	}

	return (
		<Fragment>
			<Sidenav
				open={openDrawer}
				toggleSidenav={toggle}
				width={downSm ? "85vw" : "50vw"}
				handle={
					<IconButton
						sx={{
							flexShrink: 0,
							color: "grey.600",
						}}
						aria-label='Ouvrir le menu de navigation par section'
						title='Ouvrir le menu de navigation par section'>
						<Menu />
					</IconButton>
				}
				title={"Naviguer par section"}>
				<Scrollbar autoHide={false}>
					<Box
						margin='auto'
						position='relative'
						height='100%'
						width='100%'>
						<ButtonGroup
							fullWidth
							aria-label='Main Sections Button Group'
							variant='outlined'
							color='inherit'
							size={
								downSm
									? "small"
									: "medium"
							}
							sx={{
								py: 2,
								px: 1,
								mb: 2,
								borderRadius: "4px",
							}}>
							{mainSections?.map(
								(section) => {
									const isSelected =
										section.id ===
										fashionPreference?.id;
									return (
										<Button
											key={section.id}
											onClick={() =>
												handleSectionChange(
													section
												)
											}
											variant={
												isSelected
													? "contained"
													: undefined
											}
											color={
												isSelected
													? "black"
													: undefined
											}
											sx={{
												// make sure that button on extreme left and right have border radius
												...(isSelected && {
													borderRadius:
														"4px",
												}),
											}}>
											{section.name}
										</Button>
									);
								}
							)}
						</ButtonGroup>

						{/* {renderLevels({
							data: navList,
							handleClick:
								handleNavigation,
						})} */}
						<CategoryVerticalMenu
							category={
								fashionPreference
							}
						/>
					</Box>
				</Scrollbar>
			</Sidenav>
		</Fragment>
	);
};

export default MobileMenu;
